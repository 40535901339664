/* Slider */
var carousel_resize_lock = false;
var scrollPoints = [];
var scrollBlock = false;

$("document").ready(function(){
    $('a[href*="#"]').on('click',function(e) {
      if($(this).parents(".accordion").length > 0){
        e.preventDefault();
        return true;
      }
      var target = this.hash;
      var $target = $(target);
      if($target.length > 0){
        e.preventDefault();
        scrollBlock = true;
        $(this).parent("li").addClass("current_page_item");
        $('html, body').stop().animate({
          'scrollTop': $target.offset().top-$(".top-navigation").height()
        }, 900, 'swing', function () {
          history.pushState(null,null,target);
          scrollBlock = false;
        });
      }
    });

    /* Navigation */
    $(".navigation--toggle").click(function(){
        $(this).parents(".top-navigation").toggleClass("open");
        $("body").toggleClass("navigation-open");
    });
    $(".navigation li.page_item_has_children > a > .open-dropdown").click(function(e){
        if($(window).width() > 992 && $(window).width() > $(window).height()) {
            return true;
        }
        e.preventDefault();
        $(this).parents("a").next(".children").slideToggle();
        $(this).toggleClass("open");
    });
    /* Navigation Mobile Effects */
    var i = 0.4;
    var transition_delay_css = "-webkit-transition-delay: duration;-moz-transition-delay: duration;-ms-transition-delay: duration;-o-transition-delay: duration;transition-delay: duration;";
    $(".navigation .menu > ul > li.page_item").each(function(){
        var item_delay = transition_delay_css.replace(/duration/g,i+"s");
        $(this).attr("style",item_delay);
        i += 0.1;
    });
    var item_delay = transition_delay_css.replace(/duration/g,i+"s");
    $(".navigation .language-navigation ul").attr("style",item_delay);

    /* Slider */
    $(".owl-carousel").owlCarousel({
        "items": 1,
        "loop": true,
        "autoplay": true,
        "autoplayTimeout": 7000
    });
    $(".owl-carousel").on('translate.owl.carousel', function(event) {
        $(".playing").removeClass("playing");
        $(this).find("video").trigger("pause");
    });
    $(".slide--play-overlay").click(function(){
        var parent = $(this).parents(".slide");
        var video = parent.find("video");
        parent.toggleClass("playing");
        video.trigger( video.prop('paused') ? 'play' : 'pause');
        if(parent.hasClass("video-controls")){
          window.setTimeout(function(){
            parent.find(".slide--play-overlay").remove();
            parent.find(".slide--content.slide--content--overlay").remove();
            parent.find("img").remove();
          }, 400)
        }
    });
    $(".slider .arrow").click(function(e){
      e.stopPropagation();
      scrollBlock = true;
      $('html, body').stop().animate({
        'scrollTop': $('html, body').offset().top+600
      }, 300, 'swing', function () {
        scrollBlock = false;
      });
    });

    if($(".sidebar-nav-menu").length > 0){
      window.setTimeout(function(){
        $(".sidebar-nav-menu").removeClass("active");
      }, 5000);
    }

    /* Quote Images */
    //Set position of dots at bottom of image - only for mobile portrait
    if($(window).width() < 992 && $(window).width() < $(window).height()){
        var img_height = $(".quote-images img").height();
        $(".quote-images .owl-dots").css("top", img_height-parseInt($(".quote-images .owl-dots").css("bottom"))-5);
        $(".quote-images .owl-dots").css("bottom", "auto");
    }

    /*window.sr = ScrollReveal();
    sr.reveal('.module .reveal', {reset: true});
    sr.reveal('.module .reveal-sequence', 250);*/

    var controller = new ScrollMagic.Controller({globalSceneOptions: {

    }});

    var modules = document.querySelectorAll(".module");
    for(var i = 0; i < modules.length; i++){
        new ScrollMagic.Scene({
            triggerElement: modules[i],
            triggerHook: "onEnter",
            offset: "200"
        })
            .setClassToggle(modules[i], "scroll-active")
            //.addIndicators()
            .addTo(controller);
    }

    $(".accordion--toggle").click(function(e){
        e.preventDefault();
        e.stopPropagation();
        var target = $(this).attr("data-target");
        var parent = $(this).parents(".accordion");
        var target_element = parent.find("#"+target);
        var target_element_body = parent.find("#accordion-entry-body-"+target);
        if(target_element.hasClass("open")){
            target_element.removeClass("open")
            target_element_body.slideUp('fast');
            history.pushState("", document.title, window.location.pathname + window.location.search);
        }else{
            history.pushState({}, '', "#"+target);
            if(parent.find(".accordion--entry.open").length > 0){
                parent.find(".accordion--entry.open").find(".accordion--entry--body").slideUp('fast');
                parent.find(".accordion--entry.open").removeClass("open");
                parent.find("#"+target).addClass("open");
                parent.find("#accordion-entry-body-"+target).slideDown('fast');
            }else{
                parent.find("#"+target).addClass("open");
                parent.find("#accordion-entry-body-"+target).slideDown('fast');
            }
        }
    });

    if(window.location.hash !== ""){
        var target = window.location.hash;
        target = target.replace("#", "");
        var entry = $("#"+target);
        //window.scrollTo(0, entry.offset().top-$(".top-navigation").height());
        entry.addClass("open");
        $("#accordion-entry-body-"+target).slideDown('fast');
    }

  $(".teaser.teaser--video").click(function(){
    var video = $(this).find("video");
    $(this).toggleClass("playing");
    video.trigger( video.prop('paused') ? 'play' : 'pause');
  });
  $("video").bind("ended",function(){
    var parent = $(this).parents(".video-parent");
    parent.removeClass("playing");
  });

  $(".popup .close").click(function(){
    $(this).parents(".popup").fadeOut(function(){
      $(this).remove();
    });
  });
});

$(window).on('load', function(){
  //scrollspy
  $('.top-navigation a[href*="#"]').each(function(){
    var $target = $(this.hash);
    if($target.length > 0) {
      scrollPoints.push({
        top: $target.offset().top - $(".top-navigation").height() - 1,
        bottom: $target.offset().top - $(".top-navigation").height() + $target.height() + 1,
        element: $(this).parent("li")
      })
    }
  });
  $(window).scroll(scrollspy);
  function scrollspy(){
    var scrollTop = $(window).scrollTop();
    navigationScrollSwitch(scrollTop);
    if(!scrollBlock){
      for(var i = 0; i < scrollPoints.length; i++){
        if(scrollTop >= scrollPoints[i].top && scrollTop <= scrollPoints[i].bottom){
          scrollPoints[i].element.addClass("current_page_item");
        }else{
          scrollPoints[i].element.removeClass("current_page_item");
        }
      }
    }
  }

  function navigationScrollSwitch(scrollTop){
    if($(".top-navigation.navigation--scroll-switch").length > 0){
      if(scrollTop > $(window).height()/2){
        $(".top-navigation").addClass("scrolled");
      }else{
        $(".top-navigation").removeClass("scrolled");
      }
    }
  }
  navigationScrollSwitch($(window).scrollTop());

  if(window.location.hash){
    var target = window.location.hash;
    var $target = $(target);
    if($target.length > 0) {
      scrollBlock = true;
      $('a[href*="'+window.location.hash+'"]').parent("li").addClass("current_page_item");
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top-$(".top-navigation").height() + 1
      }, 900, 'swing', function () {
        history.pushState(null,null,target);
        scrollBlock = false;
      });
    }
  }
});

$(window).resize(function() {
    carousel_resize_lock = true;
    if($(window).width() > 992 && $(window).width() > $(window).height()) {
        $(".quote-images .owl-dots").removeAttr("style");
    }
});